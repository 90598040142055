import type { FC } from "react";

import { BookmarkIconAsButton } from "components";
import { BookmarkedAssignmentsNavigation } from "features";
import { BlacklistIconAsButton } from "features/Blacklist/BlacklistToggleButton";

interface Props {
  assignmentId: string;
  subjectName: string | undefined;
  title: string;
  onNavigate: (assignmentId: string) => void;
}

export const BookmarkedAssignmentDetailHeader: FC<Props> = ({ assignmentId, subjectName, title, onNavigate }) => {
  return (
    <div className="assignment-detail-header">
      <div className="assignment-detail-header__title">
        <h1 className="mb-0">{title}</h1>
      </div>
      <div className="assignment-navigation me-4px pe-2">
        <BlacklistIconAsButton className="ms-4px" userAssignmentId={assignmentId} />
        <BookmarkIconAsButton className="ms-4px" userAssignmentId={assignmentId} />
      </div>
      <div className="border-start border-1 ps-2">
        <BookmarkedAssignmentsNavigation
          assignmentId={assignmentId}
          subjectName={subjectName}
          onNavigate={onNavigate}
        />
      </div>
    </div>
  );
};
