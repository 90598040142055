import { useEffect } from "react";

import { useGetDashboardDataQuery } from "api/generated";

import { processApiError } from "utils/processApiError";
import { useCurrentWeekDateRange } from "utils/hooks/useCurrentWeekDateRange";

export const useDashboardData = () => {
  const { dateFrom, dateTo } = useCurrentWeekDateRange();
  const response = useGetDashboardDataQuery({ timetableFrom: dateFrom, timetableTo: dateTo });

  useEffect(() => {
    response.refetch().catch(console.error);
  }, []);

  useEffect(() => {
    if (response.error === undefined) return;

    processApiError(response.error);
  }, [response.error]);

  return response;
};
