import { Navigate, Route, Routes } from "react-router-dom";

import { BookmarksOverview } from "../Bookmarks/Overview";
import { BookmarksAssignmentDetail } from "../Bookmarks/AssignmentDetail";

export const BookmarksRoutes = () => {
  return (
    <Routes>
      <Route index element={<BookmarksOverview />} />
      <Route path="/:userAssignmentId" element={<BookmarksAssignmentDetail />} />

      <Route path="*" element={<Navigate to="/" />} />
    </Routes>
  );
};
