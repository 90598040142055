import { useEffect } from "react";
import type { FC, ReactElement } from "react";
import { useLocation } from "react-router-dom";

import { useTimetableData } from "utils/hooks/useTimetableData";
import { useWeekData } from "utils/hooks/useWeekData";

import { ExerciseSessionFinished } from "./ExerciseSessionFinished";

interface Props {
  children: ReactElement;
}

export const ExerciseSessionArea: FC<Props> = ({ children }) => {
  const location = useLocation();
  const timetable = useTimetableData();
  const { isTrainingFinished } = useWeekData(timetable.data);

  useEffect(() => {
    timetable.refetch().catch(console.error);
  }, [location.key]);

  if (isTrainingFinished) {
    return <ExerciseSessionFinished />;
  }

  return children;
};
