import type { FC } from "react";
import { Badge, Nav, Tab } from "react-bootstrap";

import { AssignmentBox, Pagination } from "components";
import type { IAssignment } from "components/AssignmentBox/AssignmentBox";

export interface IAssignmentsOverviewTabAssignment extends IAssignment {
  questionText: string;
  questionHeadings: string[];
  subjectName: string;
}

interface Props {
  assignments: IAssignmentsOverviewTabAssignment[];
  subjects: string[];
  fallbackText: string;
  activeTab: string | null;
  currentPage: number;
  onPageChange: (page: number) => void;
  onTabChange: (tab: string) => void;
}

export const AssignmentsOverviewTab: FC<Props> = ({
  assignments,
  fallbackText,
  subjects,
  activeTab,
  currentPage,
  onPageChange,
  onTabChange,
}) => {
  const assignmentsBySubject = new Map<string, IAssignmentsOverviewTabAssignment[]>();

  for (const subjectName of subjects) {
    const subjectAssignments = assignments.filter(assignment => assignment.subjectName === subjectName);

    assignmentsBySubject.set(subjectName, subjectAssignments);
  }

  const subjectNames = subjects.sort((a, b) => a.localeCompare(b));
  const defaultActiveTab = subjectNames.find(name => name === activeTab) ?? subjectNames[0];

  return (
    <Tab.Container
      defaultActiveKey={defaultActiveTab}
      onSelect={tab => {
        typeof tab === "string" && onTabChange(tab);
      }}
    >
      <Nav>
        {subjectNames.map(subject => {
          return (
            <Nav.Item key={subject}>
              <Nav.Link eventKey={subject}>
                {subject}
                <Badge bg={""} className={"badge-circular"}>
                  {assignmentsBySubject.get(subject)?.length ?? 0}
                </Badge>
              </Nav.Link>
            </Nav.Item>
          );
        })}
      </Nav>

      <Tab.Content className={""}>
        {subjectNames.map(subject => {
          const assignmentsList = assignmentsBySubject.get(subject) ?? [];
          const pagesCount = Math.ceil(assignmentsList.length / 10);

          return (
            <Tab.Pane key={subject} eventKey={subject}>
              <>
                {assignmentsList.length === 0 ? (
                  <p className={"my-auto text-center"}>{fallbackText}</p>
                ) : (
                  assignmentsList?.slice((currentPage - 1) * 10, currentPage * 10).map(assignment => {
                    return <AssignmentBox key={assignment.href} assignment={assignment} />;
                  })
                )}

                {pagesCount > 1 ? (
                  <Pagination
                    className={"mt-16px"}
                    current={currentPage}
                    total={pagesCount}
                    onNavigation={onPageChange}
                  />
                ) : null}
              </>
            </Tab.Pane>
          );
        })}
      </Tab.Content>
    </Tab.Container>
  );
};
