import { Route, Routes } from "react-router-dom";

import LayoutSimple from "pages/LayoutSimple";
import LayoutSimpleWide from "pages/LayoutSimpleWide";

import Intro from "./01_Intro/Intro";
import Exercise from "./Exercise";

const ExerciseRoutes = () => {
  return (
    <Routes>
      <Route path="/*" element={<LayoutSimple />}>
        <Route index element={<Intro />} />
      </Route>

      <Route path="" element={<LayoutSimpleWide />}>
        <Route path="/:sessionId" element={<Exercise />} />
      </Route>
    </Routes>
  );
};

export default ExerciseRoutes;
