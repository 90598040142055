import { useEffect, useState } from "react";

import type { GetBookmarksAndSubjectsQuery } from "api/generated";
import type { IAssignmentsOverviewTabAssignment } from "./AssignmentsOverviewTab";

export const useAssignments = (
  bookmarks: GetBookmarksAndSubjectsQuery["bookmarks"] | undefined,
  getTopicHref: (userAssignmentId: string) => string,
) => {
  const [assignments, setAssignments] = useState<IAssignmentsOverviewTabAssignment[] | null>(null);

  useEffect(() => {
    if (bookmarks === undefined) return;

    const parsedAssignments: IAssignmentsOverviewTabAssignment[] = [];

    for (const bookmark of bookmarks) {
      if (
        typeof bookmark.userAssignment.subject?.appName !== "string" ||
        bookmark.userAssignment.assignment?.isActive === undefined ||
        !bookmark.userAssignment.assignment?.isActive
      )
        continue;

      parsedAssignments.push({
        id: bookmark.userAssignment.assignment.id,
        text: bookmark.userAssignment.assignment?.assignment?.assignmentText ?? "",
        questionText: bookmark.userAssignment.assignment?.assignment?.questionsText ?? "",
        questionHeadings:
          bookmark.userAssignment.assignment?.assignment?.questions.map(question => question.heading ?? "") ?? [],
        href: getTopicHref(bookmark.userAssignment.id),
        testId: bookmark.userAssignment.assignment?.general?.admissionTest.name ?? null,
        testOrder: bookmark.userAssignment.assignment?.general?.testOrder ?? null,
        envelopeType: bookmark.userAssignment.envelope,
        subjectName: bookmark.userAssignment.subject?.appName,
      });
    }

    setAssignments(parsedAssignments);
  }, [bookmarks]);

  return assignments;
};
