import { useEffect, useState } from "react";
import { Breadcrumb, Container } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Link, useNavigate, useParams } from "react-router-dom";

import { EnvelopeAssignmentDetailHeader, Loading } from "components";
import IconArrowBack from "components/Icons/IconArrowBack/IconArrowBack";
import { AssignmentDetailPractice } from "features";

import { useAppDispatch, useAppSelector } from "store/hooks";
import { setSimpleAnswer, setStructuredAnswer } from "store/slices/answer";
import { getRedEnvelopeParams, getOrangeEnvelopeParams, getGreenEnvelopeParams } from "store/slices/queryParams";

import { useGetUserAssignmentQuery } from "api/generated";
import type { UserAssignmentDetailFragment } from "api/generated";

import { processApiError } from "utils/processApiError";

const EnvelopeAssignmentDetail = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation(["common"]);
  const { envelopeId, superId, combinedId, assignmentId } = useParams();
  const navigate = useNavigate();
  const redEnvelopeParams = useAppSelector(getRedEnvelopeParams(combinedId));
  const orangeEnvelopeParams = useAppSelector(getOrangeEnvelopeParams(combinedId));
  const greenEnvelopeParams = useAppSelector(getGreenEnvelopeParams(combinedId));

  if (envelopeId === undefined || superId === undefined || combinedId === undefined || assignmentId === undefined) {
    navigate("/");
    return null;
  }

  const [assignmentData, setAssignmentData] = useState<UserAssignmentDetailFragment | null>(null);

  const { data, isLoading, error: apiError } = useGetUserAssignmentQuery({ assignmentId });

  const testId = assignmentData?.assignment?.general?.admissionTest.name ?? "";
  const testOrder = assignmentData?.assignment?.general?.testOrder;
  const title = typeof testId === "string" && typeof testOrder === "number" ? `${testId}_${testOrder}` : "";

  useEffect(() => {
    return () => {
      dispatch(setSimpleAnswer([]));
      dispatch(setStructuredAnswer([]));
    };
  }, []);

  useEffect(() => {
    if (apiError === undefined) return;

    processApiError(apiError);
  }, [apiError]);

  useEffect(() => {
    if (data === undefined) return;

    setAssignmentData(data.userAssignments.items[0]);
  }, [data]);

  const navigateAssignment = (newAssignmentId: string) => {
    setAssignmentData(null);
    navigate(`/ulohy/obalka/${envelopeId}/${superId}/${combinedId}/${newAssignmentId}`);
  };

  const generateQueryString = (params: Record<string, string>) => {
    let urlSlug = "";

    for (const [paramName, paramValue] of Object.entries(params)) {
      urlSlug += urlSlug.length === 0 ? "?" : "&";

      urlSlug += `${paramName}=${paramValue}`;
    }

    return urlSlug;
  };

  const getSearchValue = () => {
    switch (envelopeId) {
      case "cervena":
        return generateQueryString(redEnvelopeParams ?? {});
      case "oranzova":
        return generateQueryString(orangeEnvelopeParams ?? {});
      case "zelena":
        return generateQueryString(greenEnvelopeParams ?? {});
      default:
        return null;
    }
  };

  const returnBackQueryString = getSearchValue();

  return (
    <>
      <div className={"page-header  page-header--has-tabs"}>
        <Container className={"container-mw-md"}>
          <Breadcrumb>
            <li className={"breadcrumb-item"}>
              <Link
                to={{
                  pathname: `/ulohy/obalka/${envelopeId}/${superId}/${combinedId}`,
                  ...(returnBackQueryString === null ? null : { search: returnBackQueryString }),
                }}
              >
                <IconArrowBack />
                {t("back", { ns: "common" })}
              </Link>
            </li>
          </Breadcrumb>

          <EnvelopeAssignmentDetailHeader
            assignmentId={assignmentId}
            combinedId={combinedId}
            envelopeId={envelopeId}
            onNavigate={navigateAssignment}
            title={title}
          />
        </Container>
      </div>

      {isLoading || assignmentData === null ? (
        <Loading />
      ) : (
        <AssignmentDetailPractice assignment={assignmentData} userAssignmentId={assignmentId} />
      )}
    </>
  );
};

export default EnvelopeAssignmentDetail;
