import { useState, useEffect } from "react";
import type { GetDashboardDataQuery, WorstTopicsFragment } from "api/generated";

export const useTrainTopics = (data: GetDashboardDataQuery | undefined) => {
  const [trainTopics, setTrainTopics] = useState<WorstTopicsFragment[]>([]);

  useEffect(() => {
    if (data === undefined) return;

    const worstTopics = [...data.userTopicsWorst];
    setTrainTopics(worstTopics.sort((a, b) => (a.abilityScoreAll ?? 0) - (b.abilityScoreAll ?? 0)));
  }, [data]);

  return trainTopics;
};
