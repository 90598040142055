import { useEffect, useState } from "react";
import type { FC } from "react";
import { Breadcrumb, Container } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";

import { TopicAssignmentDetailHeader, Loading } from "components";
import IconArrowBack from "components/Icons/IconArrowBack/IconArrowBack";
import { AssignmentDetailPractice } from "features";

import { useAppDispatch, useAppSelector } from "store/hooks";
import { setSimpleAnswer, setStructuredAnswer } from "store/slices/answer";
import { getAssignmentsGlobalParams, getAssignmentsListParams } from "store/slices/queryParams";

import { useGetUserAssignmentQuery } from "api/generated";
import type { UserAssignmentDetailFragment } from "api/generated";

import { processApiError } from "utils/processApiError";

interface Props {
  superId: string | undefined;
  combinedId: string | undefined;
  assignmentId: string;
}

const AssignmentDetailContent: FC<Props> = ({ superId, combinedId, assignmentId }) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation(["common"]);
  const globalQueryParams = useAppSelector(getAssignmentsGlobalParams);
  const listQueryParams = useAppSelector(getAssignmentsListParams(combinedId));

  const [assignmentData, setAssignmentData] = useState<UserAssignmentDetailFragment | null>(null);

  const { data, isLoading, error: apiError } = useGetUserAssignmentQuery({ assignmentId });

  const testId = assignmentData?.assignment?.general?.admissionTest.name ?? "";
  const testOrder = assignmentData?.assignment?.general?.testOrder;
  const title = typeof testId === "string" && typeof testOrder === "number" ? `${testId}_${testOrder}` : "";

  useEffect(() => {
    return () => {
      dispatch(setSimpleAnswer([]));
      dispatch(setStructuredAnswer([]));
    };
  }, []);

  useEffect(() => {
    if (apiError === undefined) return;

    processApiError(apiError);
  }, [apiError]);

  useEffect(() => {
    if (data === undefined) return;

    setAssignmentData(data.userAssignments.items[0]);
  }, [data]);

  const getBackUrl = () => {
    if (superId === undefined || combinedId === undefined) {
      let urlSlug = "/ulohy";
      let first = true;

      for (const [paramName, paramValue] of Object.entries(globalQueryParams)) {
        if (!first) {
          urlSlug += `&${paramName}=${paramValue}`;
        }

        if (first) {
          urlSlug += `?${paramName}=${paramValue}`;
          first = false;
        }
      }

      return urlSlug;
    }

    let urlSlug = `/ulohy/${superId}/${combinedId}`;
    if (listQueryParams === null || listQueryParams === undefined) return urlSlug;

    let first = true;

    for (const [paramName, paramValue] of Object.entries(listQueryParams)) {
      if (!first) {
        urlSlug += `&${paramName}=${paramValue}`;
      }

      if (first) {
        urlSlug += `?${paramName}=${paramValue}`;
        first = false;
      }
    }

    return urlSlug;
  };

  const navigateAssignment = (newAssignmentId: string) => {
    setAssignmentData(null);

    if (superId === undefined || combinedId === undefined) {
      navigate(`/ulohy/detail/${newAssignmentId}`);
    } else {
      navigate(`/ulohy/${superId}/${combinedId}/${newAssignmentId}`);
    }
  };

  return (
    <>
      <div className={"page-header  page-header--has-tabs"}>
        <Container className={"container-mw-md"}>
          <Breadcrumb>
            <li className={"breadcrumb-item"}>
              <Link to={getBackUrl()}>
                <IconArrowBack />
                {t("back", { ns: "common" })}
              </Link>
            </li>
          </Breadcrumb>

          <TopicAssignmentDetailHeader
            assignmentId={assignmentId}
            combinedId={combinedId ?? "searched"}
            onNavigate={navigateAssignment}
            title={title}
          />
        </Container>
      </div>

      {isLoading || assignmentData === null ? (
        <Loading />
      ) : (
        <AssignmentDetailPractice assignment={assignmentData} userAssignmentId={assignmentId} />
      )}
    </>
  );
};

export default AssignmentDetailContent;
