import { useEffect, useState } from "react";
import type { FC } from "react";

import Layout from "./Layout";

import { getAnalysisDetailByType } from "utils/getAnalysisDetailByType";
import { getAssignmentDetailByType } from "utils/getAssignmentByType";

import type { UserAssignmentDetailFragment } from "api/generated";

import type { TTabs } from "./tabs.type";

interface Props {
  assignment: UserAssignmentDetailFragment | undefined | null;
  userAssignmentId: string;
}

export const AssignmentDetailPractice: FC<Props> = ({ assignment, userAssignmentId }) => {
  const tabs: TTabs[] = ["Zadání úlohy", "Výsledky a rozbory"];

  const [activeTab, setActiveTab] = useState<TTabs>(tabs[0]);
  const [data, setData] = useState<UserAssignmentDetailFragment | null>(null);

  useEffect(() => {
    setData(assignment ?? null);
  }, [assignment]);

  const onSubmit = () => {
    setActiveTab(tabs[1]);
  };

  const changeActiveTab = (activeTab: TTabs | null) => {
    setActiveTab(activeTab ?? tabs[0]);
  };

  if (data === null) {
    /* TODO: translate */
    return <h3 className={"my-5 text-center"}>Nepodařilo se načíst detail úlohy</h3>;
  }

  return (
    <Layout
      key={userAssignmentId}
      assignment={getAssignmentDetailByType(data.assignment?.assignment?.assignmentType.type, data, onSubmit)}
      analysis={getAnalysisDetailByType(data.assignment?.assignment?.assignmentType.type, {
        assignment: data.assignment,
        id: userAssignmentId,
      })}
      tabs={tabs}
      activeTab={activeTab}
      changeActiveTab={changeActiveTab}
    />
  );
};
