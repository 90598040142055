import { useEffect, useState } from "react";

import { useAppDispatch, useAppSelector } from "store/hooks";
import {
  areBlacklistItemsLoaded,
  saveBlacklistItem,
  saveBlacklistItems,
  deleteBlacklistItem,
  getBlacklistItem,
} from "store/slices/blacklistItems";

import {
  useLazyGetAllBlacklistItemsQuery,
  useDeleteBlacklistItemMutation,
  useSaveBlacklistItemMutation,
} from "api/generated";

import { processApiError } from "utils/processApiError";

export const useBlacklistItems = (assignmentId: string) => {
  const [getBlacklistItems, { isLoading, error: apiLazyError }] = useLazyGetAllBlacklistItemsQuery();
  const [deleteBlacklistItemMutation, { error: apiDeleteError }] = useDeleteBlacklistItemMutation();
  const [saveBlacklistItemMutation, { error: apiSaveError }] = useSaveBlacklistItemMutation();

  const blacklistItemsLoaded = useAppSelector(areBlacklistItemsLoaded);
  const blacklistItem = useAppSelector(getBlacklistItem(assignmentId));
  const dispatch = useAppDispatch();

  const [isLoadingData, setLoadingData] = useState(false);

  useEffect(() => {
    if (apiLazyError === undefined) return;

    processApiError(apiLazyError);
  }, [apiLazyError]);

  useEffect(() => {
    if (apiDeleteError === undefined) return;

    processApiError(apiDeleteError);
  }, [apiDeleteError]);

  useEffect(() => {
    if (apiSaveError === undefined) return;

    processApiError(apiSaveError);
  }, [apiSaveError]);

  const toggleBlacklistItem = () => {
    if (Boolean(isLoading) || isLoadingData) return;

    setLoadingData(true);

    if (blacklistItem === undefined) {
      saveBlacklistItemMutation({ assignmentId })
        .then(response => {
          if ("data" in response) {
            const blacklistItemData = {
              blacklistItemId: response.data.setBlacklistItem.id,
              assignmentId,
            };
            dispatch(saveBlacklistItem(blacklistItemData));
          }
        })
        .catch(error => {
          console.error(error);
        })
        .finally(() => {
          setLoadingData(false);
        });
      return;
    }

    deleteBlacklistItemMutation({ blacklistItemId: blacklistItem.blacklistItemId })
      .then(response => {
        if ("data" in response) {
          dispatch(deleteBlacklistItem({ assignmentId }));
        }
      })
      .catch(error => {
        console.error(error);
      })
      .finally(() => {
        setLoadingData(false);
      });
  };

  useEffect(() => {
    if (blacklistItemsLoaded) return;

    getBlacklistItems()
      .then(blacklistItems => {
        if ("data" in blacklistItems) {
          if (blacklistItems.data === undefined) return;

          const data = blacklistItems.data.blacklistItems.map(blacklistItem => {
            return {
              blacklistItemId: blacklistItem.id,
              assignmentId: blacklistItem.userAssignment.id,
            };
          });
          dispatch(saveBlacklistItems(data));
        }
      })
      .catch(error => {
        console.error(error);
      });
  }, []);

  return { toggleBlacklistItem, isLoadingData, isBlacklisted: blacklistItem !== undefined };
};
