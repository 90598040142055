import { useGetSubjectsQuery } from "api/generated";
import { useEffect } from "react";
import { processApiError } from "utils/processApiError";
import { BooleanWithTypeGuard } from "utils/types/BooleanWithTypeGuard";

export const useSubjects = () => {
  const { data, error, ...rest } = useGetSubjectsQuery();

  const subjects = data?.subjects
    .map(subject => subject.appName)
    .filter(BooleanWithTypeGuard)
    .sort((a, b) => a.localeCompare(b));

  useEffect(() => {
    if (error === undefined) return;

    processApiError(error);
  }, [error]);

  return { data: subjects, error, ...rest };
};
