import { Navigate, Route, Routes } from "react-router-dom";

import { BlacklistOverview } from "../Blacklist/Overview";
import { BlacklistAssignmentDetail } from "../Blacklist/AssignmentDetail";

export const BlacklistRoutes = () => {
  return (
    <Routes>
      <Route index element={<BlacklistOverview />} />
      <Route path="/:userAssignmentId" element={<BlacklistAssignmentDetail />} />

      <Route path="*" element={<Navigate to="/" />} />
    </Routes>
  );
};
