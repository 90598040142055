import { useEffect } from "react";
import type { FC } from "react";
import { Button } from "react-bootstrap";

import { useAppSelector, useAppDispatch } from "store/hooks";
import { getBlacklistedAssignments, setBlacklistedAssignments } from "store/slices/assignmentNavigation";

import { useLazyGetAllBlacklistItemsWithSubjectQuery } from "api/generated";

import { processApiError } from "utils/processApiError";

import { ReactComponent as IconArrowLeft } from "images/icons/arrow-left.svg";
import { ReactComponent as IconArrowRight } from "images/icons/arrow-right.svg";

interface Props {
  assignmentId: string;
  subjectName: string | undefined;
  onNavigate: (assignmentId: string) => void;
}

export const BlacklistedAssignmentsNavigation: FC<Props> = ({ assignmentId, subjectName, onNavigate }) => {
  const dispatch = useAppDispatch();

  const [trigger, { error: apiLazyError }] = useLazyGetAllBlacklistItemsWithSubjectQuery();
  const assignmentsList = useAppSelector(getBlacklistedAssignments(subjectName));

  useEffect(() => {
    if (apiLazyError === undefined) return;

    processApiError(apiLazyError);
  }, [apiLazyError]);

  useEffect(() => {
    if (assignmentsList === null || assignmentsList.length === 0) {
      trigger()
        .then(response => {
          if ("error" in response) {
            console.error(response.error);
            return;
          }

          if (response.data === undefined) return;

          const idList = response.data.blacklistItems.map(blacklistItem => {
            return {
              id: blacklistItem.userAssignment.id,
              subjectName: blacklistItem.userAssignment.subject?.appName ?? undefined,
            };
          });

          dispatch(setBlacklistedAssignments(idList));
        })
        .catch(error => {
          console.error(error);
        });
    }
  }, [assignmentsList]);

  const navigatePrevious = () => {
    if (assignmentsList === null || assignmentsList.length <= 1) return;

    const index = assignmentsList.findIndex(assignment => assignment.id === assignmentId);

    if (index === -1) return;
    if (index === 0) {
      onNavigate(assignmentsList[assignmentsList.length - 1].id);
      return;
    }

    onNavigate(assignmentsList[index - 1].id);
  };

  const navigateNext = () => {
    if (assignmentsList === null || assignmentsList.length <= 1) return;

    const index = assignmentsList.findIndex(assignment => assignment.id === assignmentId);

    if (index === -1) return;
    if (index >= assignmentsList.length - 1) {
      onNavigate(assignmentsList[0]?.id);
      return;
    }

    const newIndex = index + 1;
    onNavigate(assignmentsList[newIndex].id);
  };

  return (
    <div className="assignment-navigation">
      <Button variant={"select"} className={"ms-4px btn-shadow"} onClick={navigatePrevious}>
        <IconArrowLeft />
      </Button>
      <Button variant={"select"} className={"ms-4px btn-shadow"} onClick={navigateNext}>
        <IconArrowRight />
      </Button>
    </div>
  );
};
