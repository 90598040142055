import { useEffect, useRef, useState } from "react";
import type { FC } from "react";
import { Alert } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { BlackEnvelopeTopicModal } from "components";

import { processApiError } from "utils/processApiError";

import { useRemoveTopicFromBlackEnvelopeMutation } from "api/generated";
import type { BlindSpotTopicFragment } from "api/generated";

const DEFAULT_SHOWN_TOPIC_COUNT = 5;

export interface ITopic {
  id: string;
  combinedTopicId: string;
  combinedTopicName: string;
  superTopicId: string;
}

interface Props {
  data: BlindSpotTopicFragment[] | undefined;
}

const BlackEnvelope: FC<Props> = ({ data }) => {
  if (data === undefined) return null;

  const { t } = useTranslation(["envelope"]);
  const navigate = useNavigate();

  const [removeEnvelope, { error: apiMutationError }] = useRemoveTopicFromBlackEnvelopeMutation();

  const [topics, setTopics] = useState<ITopic[]>([]);
  const [shownTopicsCount, setShownTopicsCount] = useState(DEFAULT_SHOWN_TOPIC_COUNT);
  const [activeTopicModal, setActiveTopicModal] = useState<ITopic | null>(null);

  const mutationLoading = useRef(false);

  useEffect(() => {
    if (apiMutationError === undefined) return;

    processApiError(apiMutationError);
  }, [apiMutationError]);

  useEffect(() => {
    if (data === undefined) return;

    const blackEnvelopeTopics = data
      .map(topic => {
        return {
          id: topic.id,
          combinedTopicId: topic.combinedTopic?.id,
          combinedTopicName: topic.combinedTopic?.name,
          superTopicId: topic.superTopic?.id,
        };
      })
      .filter((topic): topic is ITopic => typeof topic.combinedTopicName === "string");
    setTopics(blackEnvelopeTopics);
  }, [data]);

  const handleShowAll = () => {
    setShownTopicsCount(topics.length);
  };

  const handleClick = (topic: ITopic) => {
    setActiveTopicModal(topic);
  };

  const handleModalClose = () => {
    setActiveTopicModal(null);
  };

  const handleModalConfirm = (topicId: string) => {
    if (mutationLoading.current) return;
    mutationLoading.current = true;

    removeEnvelope({ id: topicId })
      .then(() => {
        setActiveTopicModal(null);
        setTopics(currentTopics => {
          const copy = [...currentTopics].filter(currentTopic => currentTopic.id !== topicId);

          return copy;
        });
      })
      .catch(error => {
        console.error(error);
      })
      .finally(() => {
        mutationLoading.current = false;
      });
  };

  const handleShowExercises = (superTopicId: string, combinedTopicId: string) => {
    navigate(`/ulohy/${superTopicId}/${combinedTopicId}`);
  };

  if (topics.length === 0) return null;

  return (
    <>
      <h4 className={"mb-3 mt-25px"}>{t("blackEnvelope.title", { ns: "envelope" })}</h4>
      <Alert variant={"danger"}>
        <p>{t("blackEnvelope.summary1", { ns: "envelope" })}</p>
        <p>{t("blackEnvelope.summary2", { ns: "envelope" })}</p>

        <div className={"tags"}>
          {topics.slice(0, shownTopicsCount).map(topic => (
            <span
              role="button"
              key={topic.combinedTopicId}
              className={"tag"}
              onClick={() => {
                handleClick(topic);
              }}
            >
              {topic.combinedTopicName}
            </span>
          ))}
          {topics.length > shownTopicsCount ? (
            <a className={"tag"} onClick={handleShowAll} role="button">
              +{topics.length - shownTopicsCount}
            </a>
          ) : null}
        </div>
      </Alert>
      {activeTopicModal === null ? null : (
        <BlackEnvelopeTopicModal
          onClose={handleModalClose}
          onConfirm={() => {
            handleModalConfirm(activeTopicModal.id);
          }}
          onShowExercises={() => {
            handleShowExercises(activeTopicModal.superTopicId, activeTopicModal.combinedTopicId);
          }}
          topicName={activeTopicModal.combinedTopicName}
        />
      )}
    </>
  );
};

export default BlackEnvelope;
