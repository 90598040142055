import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

import type { RootState } from "store/store";

interface IQueryParamsState {
  blacklist: Record<string, string>;
  bookmarks: Record<string, string>;
  assignments: {
    global: Record<string, string>;
    list: Record<string, Record<string, string>>;
  };
  envelopes: {
    red: Record<string, Record<string, string>>;
    orange: Record<string, Record<string, string>>;
    green: Record<string, Record<string, string>>;
  };
  faq: string | null;
}

const initialState: IQueryParamsState = {
  blacklist: {},
  bookmarks: {},
  assignments: {
    global: {},
    list: {},
  },
  envelopes: {
    red: {},
    orange: {},
    green: {},
  },
  faq: null,
};

const queryParamsSlice = createSlice({
  name: "Params",
  initialState,
  reducers: {
    setBlacklistQueryParams: (state, action: PayloadAction<Record<string, string>>) => {
      state.blacklist = action.payload;
    },
    setBookmarksQueryParams: (state, action: PayloadAction<Record<string, string>>) => {
      state.bookmarks = action.payload;
    },
    setAssignmentsGlobalQueryParams: (state, action: PayloadAction<Record<string, string>>) => {
      state.assignments.global = action.payload;
    },
    setAssignmentsListQueryParams: (
      state,
      action: PayloadAction<{ topicId: string; params: Record<string, string> }>,
    ) => {
      const { topicId, params } = action.payload;

      state.assignments.list = { ...state.assignments.list, [topicId]: params };
    },
    setRedEnvelopeQueryParams: (state, action: PayloadAction<{ topicId: string; params: Record<string, string> }>) => {
      const { topicId, params } = action.payload;

      state.envelopes.red = { ...state.envelopes.red, [topicId]: params };
    },
    setOrangeEnvelopeQueryParams: (
      state,
      action: PayloadAction<{ topicId: string; params: Record<string, string> }>,
    ) => {
      const { topicId, params } = action.payload;

      state.envelopes.orange = { ...state.envelopes.orange, [topicId]: params };
    },
    setGreenEnvelopeQueryParams: (
      state,
      action: PayloadAction<{ topicId: string; params: Record<string, string> }>,
    ) => {
      const { topicId, params } = action.payload;

      state.envelopes.green = { ...state.envelopes.green, [topicId]: params };
    },
    setFaqQueryParams: (state, action: PayloadAction<string | null>) => {
      state.faq = action.payload;
    },
  },
});

export default queryParamsSlice.reducer;

export const {
  setBlacklistQueryParams,
  setBookmarksQueryParams,
  setAssignmentsGlobalQueryParams,
  setAssignmentsListQueryParams,
  setRedEnvelopeQueryParams,
  setOrangeEnvelopeQueryParams,
  setGreenEnvelopeQueryParams,
  setFaqQueryParams,
} = queryParamsSlice.actions;

export const getBlacklistQueryParams = (state: RootState) => state.queryParams.blacklist;
export const getBookmarksQueryParams = (state: RootState) => state.queryParams.bookmarks;
export const getAssignmentsGlobalParams = (state: RootState) => state.queryParams.assignments.global;
export const getAssignmentsListParams = (topicId: string | undefined) => (state: RootState) =>
  topicId === undefined ? null : state.queryParams.assignments.list[topicId];
export const getRedEnvelopeParams = (topicId: string | undefined) => (state: RootState) =>
  topicId === undefined ? null : state.queryParams.envelopes.red[topicId];
export const getOrangeEnvelopeParams = (topicId: string | undefined) => (state: RootState) =>
  topicId === undefined ? null : state.queryParams.envelopes.orange[topicId];
export const getGreenEnvelopeParams = (topicId: string | undefined) => (state: RootState) =>
  topicId === undefined ? null : state.queryParams.envelopes.green[topicId];
export const getFaqParams = (state: RootState) => state.queryParams.faq;
