import { useEffect, useState } from "react";
import { Breadcrumb, Container, Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import Button from "react-bootstrap/Button";

import type { GetBookmarksAndSubjectsQuery } from "api/generated";

import { AssignmentBox, Loading, Pagination, PracticeConfirmationModal } from "components";
import { usePracticeConfirmationModalLocalStorage } from "components/Modal/PracticeConfirmationModal/usePracticeConfirmationModalLocalStorage";
import IconArrowBack from "components/Icons/IconArrowBack/IconArrowBack";

import { PAGE_QUERY_PARAM, SEARCH_QUERY_PARAM, SUBJECT_QUERY_PARAM } from "const";

import { useQuery } from "utils/hooks/useQuery";

import { AssignmentsOverviewTab } from "./AssignmentsOverviewTab";
import { useAssignments } from "./useAssignments";
import { useSearch } from "./useSearch";
import { useSubjects } from "./useSubjects";

interface Props<TSubject = { appName?: string | null }> {
  assignments?: GetBookmarksAndSubjectsQuery["bookmarks"];
  subjects?: TSubject[];
  getTopicHref: (userAssignmentId: string) => string;
  isLoading: boolean;
  onPracticeStart: () => void;
  onQueryParamsChange: (params: Record<string, string | null>) => void;
  pageIcon: React.ReactNode;
  pageTitle: string;
}

export const AssignmentsOverview = ({
  assignments,
  subjects,
  getTopicHref,
  isLoading,
  onPracticeStart,
  onQueryParamsChange,
  pageIcon,
  pageTitle,
}: Props) => {
  const { t } = useTranslation(["common", "assignment"]);

  const { removeAllQueryParams, setEagerQueryParam, setSearchParam, searchParams } = useQuery();

  const pageString = searchParams.get(PAGE_QUERY_PARAM);
  const page = typeof pageString === "string" ? Number(pageString) : 1;

  const allAssignments = useAssignments(assignments, getTopicHref);
  const { foundAssignments, search } = useSearch(allAssignments, searchParams, removeAllQueryParams);
  const subjectsRef = useSubjects(subjects);

  const [isModalVisible, setModalVisible] = useState(false);
  const { isConfirmationModalAllowed, setSeenModalToLocalStorage } = usePracticeConfirmationModalLocalStorage();

  useEffect(() => {
    const searchParam = searchParams.get(SEARCH_QUERY_PARAM);
    const pageParam = searchParams.get(PAGE_QUERY_PARAM);
    const subjectParam = searchParams.get(SUBJECT_QUERY_PARAM);

    onQueryParamsChange({
      ...(typeof searchParam === "string" ? { [SEARCH_QUERY_PARAM]: searchParam.toString() } : null),
      ...(typeof pageParam === "string" ? { [PAGE_QUERY_PARAM]: pageParam.toString() } : null),
      ...(typeof subjectParam === "string" ? { [SUBJECT_QUERY_PARAM]: subjectParam } : null),
    });
  }, [searchParams]);

  const handleSearch = (searched: string) => {
    setEagerQueryParam(SEARCH_QUERY_PARAM, searched);
  };

  const handlePageChange = (page: number) => {
    setSearchParam(PAGE_QUERY_PARAM, page.toString());
  };

  const handleTabChange = (tab: string) => {
    setEagerQueryParam(SUBJECT_QUERY_PARAM, tab);
  };

  const handleSetModalVisible = () => {
    setSeenModalToLocalStorage();
    setModalVisible(true);
  };

  return (
    <>
      <div className={"page-header page-header--has-tabs bookmarked"}>
        <Container className={"container-mw-md"}>
          <div className="bookmarked__header">
            <div>
              <Breadcrumb>
                <li className={"breadcrumb-item"}>
                  <Link to={"/"}>
                    <IconArrowBack />
                    {t("goHome")}
                  </Link>
                </li>
              </Breadcrumb>

              <h1>{pageTitle}</h1>
            </div>

            {pageIcon}
          </div>

          <Button
            className={"text-uppercase"}
            disabled={allAssignments === null || allAssignments.length === 0}
            onClick={() => {
              isConfirmationModalAllowed ? handleSetModalVisible() : onPracticeStart();
            }}
          >
            {t("trainThisTypeOfAssignments", { ns: "assignments" })}
          </Button>

          <hr className={"my-25px"} />

          <Form
            onSubmit={event => {
              event.preventDefault();
            }}
          >
            <Form.Control
              className={"form-search"}
              placeholder={"Hledej v textu úlohy..."}
              value={search ?? ""}
              onChange={e => {
                handleSearch(e.target.value);
              }}
            />
          </Form>
        </Container>
      </div>

      <Container
        className={`container-mw-md page-container pt-25px pt-sm-45px flex-grow-1 ${
          !isLoading && foundAssignments !== null && foundAssignments.length === 0 ? "d-grid" : ""
        }`}
      >
        {isLoading || allAssignments === null ? (
          <Loading />
        ) : search === "" || search === null ? (
          <AssignmentsOverviewTab
            assignments={allAssignments}
            fallbackText={t("bookmarked.nothingSaved", { ns: "assignments" })}
            subjects={subjectsRef.current}
            activeTab={searchParams.get(SUBJECT_QUERY_PARAM)}
            currentPage={page ?? 1}
            onPageChange={handlePageChange}
            onTabChange={handleTabChange}
          />
        ) : foundAssignments === null || foundAssignments.length === 0 ? (
          <div className="pt-20px">
            <p className={"mx-5 my-auto mt-sm-3 mb-sm-0 text-center"}>{t("searchNotFound", { ns: "common" })}</p>
          </div>
        ) : (
          <div className="pt-20px">
            {foundAssignments?.slice((page - 1) * 10, page * 10).map(assignment => {
              return <AssignmentBox key={assignment.href} assignment={assignment} />;
            })}

            {foundAssignments.length > 10 ? (
              <Pagination
                className={"mt-16px"}
                current={page}
                total={Math.ceil(foundAssignments.length / 10)}
                onNavigation={handlePageChange}
              />
            ) : null}
          </div>
        )}
      </Container>
      <PracticeConfirmationModal
        onConfirm={onPracticeStart}
        show={isModalVisible}
        onHide={() => {
          setModalVisible(false);
        }}
      />
    </>
  );
};
