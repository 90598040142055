import type { FC } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

interface Props {
  topicName: string;
  onClose: () => void;
  onConfirm: () => void;
  onShowExercises: () => void;
}

const BlackEnvelopeTopic: FC<Props> = ({ topicName, onClose, onConfirm, onShowExercises }) => {
  return (
    <Modal onHide={onClose} show={true}>
      <Modal.Header closeButton />
      <Modal.Body className="pt-0">
        <p>
          Téma <strong>&quot;{topicName}&quot;</strong> je pro Tebe teď trochu náročné, odebrali jsme ho z procvičování.
        </p>
        <p>
          <strong>Prober ho s rodiči, učiteli nebo na doučování. Jakmile to budeš chápat, dej nám vědět!</strong>
        </p>

        <div className={"d-grid gap-8px"}>
          <Button className={"w-100"} onClick={onConfirm}>
            Doučil jsem se,
            <br />
            chci ho zařadit do procvičování
          </Button>
          <Button variant={"outline-secondary-darker"} className={"w-100"} onClick={onClose}>
            Pracuju na tom :)
          </Button>
          <Button variant={"outline-secondary-darker"} className={"w-100"} onClick={onShowExercises}>
            Zobrazit úlohy
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default BlackEnvelopeTopic;
