import { useEffect, useState } from "react";
import { Breadcrumb, Container } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Link, useNavigate, useParams } from "react-router-dom";

import { BookmarkedAssignmentDetailHeader, Loading } from "components";
import IconArrowBack from "components/Icons/IconArrowBack/IconArrowBack";
import { AssignmentDetailPractice } from "features";

import { setTopicAssignments } from "store/slices/assignmentNavigation";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { setSimpleAnswer, setStructuredAnswer } from "store/slices/answer";
import { getBookmarksQueryParams } from "store/slices/queryParams";

import { useGetUserAssignmentQuery } from "api/generated";
import type { UserAssignmentDetailFragment } from "api/generated";

import { processApiError } from "utils/processApiError";

export const BookmarksAssignmentDetail = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation(["common"]);
  const { userAssignmentId } = useParams();
  const navigate = useNavigate();
  const bookmarksQueryParams = useAppSelector(getBookmarksQueryParams);

  const [assignmentData, setAssignmentData] = useState<UserAssignmentDetailFragment | null>(null);
  const [subjectName, setSubjectName] = useState<string | undefined>(undefined);

  if (userAssignmentId === undefined) {
    navigate("/");
    return null;
  }

  const { data, isLoading, error: apiError } = useGetUserAssignmentQuery({ assignmentId: userAssignmentId });

  const testId = assignmentData?.assignment?.general?.admissionTest.name ?? "";
  const testOrder = assignmentData?.assignment?.general?.testOrder;
  const title = typeof testId === "string" && typeof testOrder === "number" ? `${testId}_${testOrder}` : "";

  useEffect(() => {
    return () => {
      dispatch(setSimpleAnswer([]));
      dispatch(setStructuredAnswer([]));
    };
  }, []);

  useEffect(() => {
    if (apiError === undefined) return;

    processApiError(apiError);
  }, [apiError]);

  useEffect(() => {
    if (data === undefined || data.userAssignments.items.length === 0) return;

    setAssignmentData(data.userAssignments.items[0]);

    const shownAssignment = data.userAssignments.items.find(assignment => assignment.id === userAssignmentId);

    if (shownAssignment?.subject?.appName !== undefined) {
      setSubjectName(shownAssignment.subject.appName ?? undefined);
    }
  }, [data]);

  useEffect(() => {
    return () => {
      dispatch(setTopicAssignments({ topicId: "bookmark", assignments: [] }));
    };
  }, []);

  const navigateAssignment = (newAssignmentId: string) => {
    setAssignmentData(null);
    navigate(`/ulozene/${newAssignmentId}`);
  };

  const generateQueryString = (params: Record<string, string>) => {
    let queryString = "";

    for (const [paramName, paramValue] of Object.entries(params)) {
      if (queryString.length > 0) queryString += "&";

      queryString += `${paramName}=${paramValue}`;
    }

    return queryString;
  };

  return (
    <>
      <div className={"page-header  page-header--has-tabs"}>
        <Container className={"container-mw-md"}>
          <Breadcrumb>
            <li className={"breadcrumb-item"}>
              <Link
                to={{
                  pathname: "/ulozene",
                  ...(Object.keys(bookmarksQueryParams).length > 0
                    ? { search: generateQueryString(bookmarksQueryParams) }
                    : null),
                }}
              >
                <IconArrowBack />
                {t("back", { ns: "common" })}
              </Link>
            </li>
          </Breadcrumb>
          <BookmarkedAssignmentDetailHeader
            assignmentId={userAssignmentId}
            subjectName={subjectName}
            onNavigate={navigateAssignment}
            title={title}
          />
        </Container>
      </div>

      {isLoading || assignmentData === null ? (
        <Loading />
      ) : (
        <AssignmentDetailPractice
          key={userAssignmentId}
          assignment={assignmentData}
          userAssignmentId={userAssignmentId}
        />
      )}
    </>
  );
};
