import { useAppSelector } from "store/hooks";
import { getTimetableCreateDate } from "store/slices/user";
import { formatDate, getPrevMonday } from "utils/calendarDateFormatting";
import { getNearestSunday } from "utils/validateWeekRange";

export const useCurrentWeekDateRange = () => {
  const monday = formatDate(getPrevMonday(new Date()));
  const sunday = formatDate(getNearestSunday(new Date()));
  const timetableCreatedDate = useAppSelector(getTimetableCreateDate);
  const timetableDateFrom =
    timetableCreatedDate === null || monday > timetableCreatedDate ? monday : timetableCreatedDate;

  return { dateFrom: timetableDateFrom, dateTo: sunday };
};
