import { useEffect } from "react";
import { useGetTimetableQuery } from "api/generated";
import { processApiError } from "utils/processApiError";
import { useCurrentWeekDateRange } from "./useCurrentWeekDateRange";

export const useTimetableData = () => {
  const { dateFrom, dateTo } = useCurrentWeekDateRange();
  const response = useGetTimetableQuery({ dateFrom, dateTo });

  useEffect(() => {
    response.refetch().catch(console.error);
  }, []);

  useEffect(() => {
    if (response.error === undefined) return;

    processApiError(response.error);
  }, [response.error]);

  return response;
};
