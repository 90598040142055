import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { Image } from "react-bootstrap";

import { Loading } from "components/Loading";

interface Props {
  isLoading: boolean;
  onClose: () => void;
  onConfirm: () => void;
}

export const BlacklistInConfirmModal: React.FC<Props> = ({ isLoading, onClose, onConfirm }) => {
  return (
    <Modal show={true} onHide={onClose}>
      <Modal.Header closeButton />
      <Modal.Body>
        {isLoading ? (
          <Loading />
        ) : (
          <>
            <div className="mb-8px text-center">
              <Image src="/images/120-blacklist-in.svg" />
            </div>
            <h3 className="text-center mb-20px">Chceš otázku vyřadit z tréninku?</h3>
            <p className="text-center mb-20px">
              Najdeš ji potom sekci „Vyřazené úlohy“, kdykoli ji můžeš do tréninku vrátit. 🙂
            </p>
            <Button variant="outline-primary" className="w-100" onClick={onConfirm}>
              Ano, vyřadit z tréninku
            </Button>
            <Button variant="link" className="w-100" onClick={onClose}>
              Ne, ponechat v tréninku
            </Button>
          </>
        )}
      </Modal.Body>
    </Modal>
  );
};
