import { useEffect } from "react";
import type { FC } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { Image } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import { useClassroomAmbienceContext } from "features/ClassroomAmbience/context";

import { gtmExercisePaused } from "utils/gtmEvents";

interface Props {
  onClose: () => void;
  onQuit: () => void;
}

const PauseModal: FC<Props> = ({ onClose, onQuit }) => {
  const { isSoundOn, onToggleSound } = useClassroomAmbienceContext();

  const { t } = useTranslation(["common", "exercise"]);

  useEffect(() => {
    gtmExercisePaused();
  }, []);

  return (
    <Modal show={true} onHide={onClose}>
      <Modal.Header closeButton />
      <Modal.Body className="pb-40px">
        <div className="mb-8px text-center">
          <Image src={"/images/capy-paused.svg"} />
        </div>
        <div className={"d-grid gap-8px"}>
          <Button variant="outline-success" className={"w-100 text-uppercase"} onClick={onToggleSound}>
            {t(isSoundOn ? "btn.turnSoundOff" : "btn.turnSoundOn", { ns: "exercise" })}
          </Button>
          <Button variant="success" className={"w-100 text-uppercase"} onClick={onClose}>
            {t("btn.continueFurther", { ns: "exercise" })}
          </Button>
        </div>
        <p className={"text-center my-20px"}>{t("or", { ns: "common" })}</p>
        <Button variant="outline-danger" className={"w-100 text-uppercase"} onClick={onQuit}>
          {t("btn.exerciseEnd", { ns: "exercise" })}
        </Button>
      </Modal.Body>
    </Modal>
  );
};

export default PauseModal;
