export function IconTrashCan() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="15" height="18" viewBox="0 0 15 18" fill="none">
      <path
        d="M2.59668 4.6998V14.4038C2.59668 15.5083 3.49211 16.4038 4.59668 16.4038H10.4967C11.6012 16.4038 12.4967 15.5083 12.4967 14.4038V4.6998"
        stroke="#CAC7BF"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M4.74707 3.7998V3.7998C4.74707 2.79647 5.56043 1.98311 6.56377 1.98311H8.52549C9.52882 1.98311 10.3422 2.79647 10.3422 3.7998V3.7998"
        stroke="#CAC7BF"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path d="M6.2002 8.27695V12.4328" stroke="#CAC7BF" strokeWidth="2" strokeLinecap="round" />
      <path d="M8.90039 8.27695V12.4328" stroke="#CAC7BF" strokeWidth="2" strokeLinecap="round" />
      <path d="M1.7002 4.6998L13.3994 4.6998" stroke="#CAC7BF" strokeWidth="2" strokeLinecap="round" />
    </svg>
  );
}
