import type { FC } from "react";
import { PieChart, Pie, Cell } from "recharts";
import i18n from "i18next";

export const PIE_CHART_WIDTH = 330;

export interface TLabelFormatter {
  cx: number;
  cy: number;
  midAngle: number;
  innerRadius: number;
  outerRadius: number;
  percent: number;
  index: number;
}

const RADIAN = Math.PI / 180;

export interface ISubjectDataForPie {
  name: string;
  value: number;
  color: string;
}

interface Props {
  data: ISubjectDataForPie[];
}

export const SUBJECT_PIE_COLORS: Record<string, string> = {
  Matematika: "#17A4C2",
  "Český jazyk": "#F46969",
  Angličtina: "#A75FE0",
};

const SubjectsPie: FC<Props> = ({ data }) => {
  const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }: TLabelFormatter) => {
    const radius = outerRadius;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    const textXposition = x > cx ? x + 18 : x - 18;

    return (
      <>
        <circle cx={x} cy={y} r="10" fill="#3D3B32" fillOpacity="0.4" />
        <circle cx={x} cy={y} r="4" fill="white" />

        <text x={textXposition} y={y} textAnchor={x > cx ? "start" : "end"} dominantBaseline="central">
          <tspan className={"recharts-label-name"} x={textXposition} dy="0">{`${data[index]?.name}`}</tspan>
          <tspan className={"recharts-label-value"} x={textXposition} dy="1.2em">{`${i18n.t("question", {
            ns: "common",
            count: data[index]?.value,
          })}`}</tspan>
        </text>
      </>
    );
  };

  const tasksTotal = data.reduce((accumulator, currentValue) => accumulator + currentValue?.value, 0);
  const tasksFirstSubject = data[0]?.value;
  const pieStartAngle = 30 + (((tasksTotal - tasksFirstSubject) / tasksTotal) * 360) / 2;

  return (
    <PieChart width={PIE_CHART_WIDTH} height={174} className={"mx-auto"}>
      <Pie
        data={data}
        cx="50%"
        cy="50%"
        labelLine={false}
        outerRadius={55}
        dataKey="value"
        blendStroke
        legendType={"circle"}
        startAngle={pieStartAngle}
        endAngle={pieStartAngle + 360}
        label={renderCustomizedLabel}
      >
        {data.map((entry, index) => (
          <Cell key={`cell-${index}`} fill={entry?.color} />
        ))}
      </Pie>
    </PieChart>
  );
};

export default SubjectsPie;
