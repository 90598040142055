import { Button, Container, Image } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";

export const ExerciseSessionFinished = () => {
  return (
    <main className="h-100 flex-grow-1 overflow-hidden d-flex flex-column justify-content-between justify-content-sm-start">
      <div className="page">
        <Container className="container-mw-md layout--narrow mb-sm-32px">
          <div className="page__header">
            <LinkContainer to="/">
              <Button variant="close" />
            </LinkContainer>
          </div>
        </Container>

        <Container className="container-mw-sm layout--narrow mb-auto flex-grow-1">
          <div className="page__content">
            <div className="text-center">
              <Image src="/images/super-bara.svg" />
            </div>

            <p className="text-center my-4">
              Dneska už jsi úkoly zvládl, ale kapybara ti připraví nové výzvy na zítra! 📚 Nezapomeň, že každý den je
              nová šance se zlepšit. Těšíme se na tvé další úspěchy!
            </p>

            <LinkContainer to="/">
              <Button className="text-uppercase w-100">Zavřít</Button>
            </LinkContainer>
          </div>
        </Container>
      </div>
    </main>
  );
};
