import type { FC } from "react";
import { Col, Image, Row } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import TagManager from "react-gtm-module";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { capitalize } from "lodash";

import type { GetDashboardDataQuery } from "api/generated";

import { CalendarWeek, ExerciseButtons } from "features";

import strawberryIcon from "images/icons/checkberry.svg";
import snailIcon from "images/icons/drakusnail.svg";

import { useTrainTopics } from "utils/hooks/useTrainTopics";
import { useWeekData } from "utils/hooks/useWeekData";

interface Props {
  data: GetDashboardDataQuery | undefined;
}

const DashboardCalendarOverview: FC<Props> = ({ data }) => {
  const { t } = useTranslation(["dashboard", "exercise"]);
  const navigate = useNavigate();
  const { isTrainingFinished, isTrainingPartiallyDone, weekData } = useWeekData(data);
  const trainTopics = useTrainTopics(data);

  const redirectExercise = () => {
    TagManager.dataLayer({ dataLayer: { event: "start-exercise" } });

    navigate("/cviceni");
  };

  const getTexts = () => {
    if (isTrainingFinished) {
      return {
        icon: strawberryIcon,
        heading: t("training.done.heading"),
        description: t("training.done.description", {
          topicName: trainTopics?.at(0)?.topic?.name?.toLocaleLowerCase() ?? "",
        }),
      };
    }

    if (isTrainingPartiallyDone) {
      return {
        icon: snailIcon,
        heading: t("training.partially.heading"),
        description: t("training.partially.description"),
      };
    }

    return {
      icon: snailIcon,
      heading: t("training.notStarted.heading"),
      description: t("training.notStarted.description"),
    };
  };

  const { icon, heading, description } = getTexts();
  return (
    <div className="well">
      <div className="pt-6px">
        <div className="px-3 pt-8px">
          <CalendarWeek data={weekData} />
        </div>
      </div>
      <hr />
      <div className="pt-12px px-16px px-sm-28px pb-32px">
        <Row className="g-12px">
          <Col xs={"auto"} style={{ width: 50 }} className={"text-center"}>
            <Image src={icon} />
          </Col>
          <Col>
            <p className={"fw-bold mb-8px"}>{heading}</p>
            <p className={"mb-12px"}>{description}</p>
          </Col>
        </Row>
        <Row>
          <Col xs={"auto"} style={{ width: 50 }} className={"d-none d-sm-block"}></Col>
          <Col className={"well__btn"}>
            {isTrainingFinished ? (
              <ExerciseButtons topics={trainTopics} />
            ) : isTrainingPartiallyDone ? (
              <Button onClick={redirectExercise}>{capitalize(t("btn.finishTraining", { ns: "exercise" }))}</Button>
            ) : (
              <Button onClick={redirectExercise}>{capitalize(t("btn.startExercise", { ns: "exercise" }))}</Button>
            )}
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default DashboardCalendarOverview;
