import { useState } from "react";
import type { FC } from "react";
import { ToggleButton } from "react-bootstrap";

import { ReactComponent as BlacklistIcon } from "images/icons/20-blacklist.svg";

import { BlacklistInConfirmModal } from "./BlacklistInConfirmModal";
import { BlacklistOutConfirmModal } from "./BlacklistOutConfirmModal";
import { useBlacklistItems } from "./useBlacklistItems";

interface Props {
  className?: string;
  userAssignmentId: string;
}

export const BlacklistIconAsButton: FC<Props> = ({ className, userAssignmentId }) => {
  const [modalVisible, setModalVisible] = useState(false);

  const { toggleBlacklistItem, isBlacklisted, isLoadingData } = useBlacklistItems(userAssignmentId);

  const handleButtonClick = () => {
    setModalVisible(true);
  };

  const handleConfirm = () => {
    if (isLoadingData) return;

    toggleBlacklistItem();
    setModalVisible(false);
  };

  const handleModalClose = () => {
    setModalVisible(false);
  };

  return (
    <>
      <ToggleButton
        type="checkbox"
        variant="select"
        value="test"
        checked={isBlacklisted}
        className={`btn-xl btn-shadow bookmark-button ${className ?? ""}`}
        onClick={handleButtonClick}
        id={userAssignmentId}
      >
        <BlacklistIcon fill="none" />
      </ToggleButton>
      {modalVisible ? (
        isBlacklisted ? (
          <BlacklistOutConfirmModal isLoading={isLoadingData} onClose={handleModalClose} onConfirm={handleConfirm} />
        ) : (
          <BlacklistInConfirmModal isLoading={isLoadingData} onClose={handleModalClose} onConfirm={handleConfirm} />
        )
      ) : null}{" "}
    </>
  );
};
