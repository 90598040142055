import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

import type { RootState } from "store/store";

interface IBlacklistItem {
  assignmentId: string;
  blacklistItemId: string;
}

interface IBlacklistItemsState {
  blacklistItems: IBlacklistItem[];
}

const initialState: IBlacklistItemsState = {
  blacklistItems: [],
};

const blacklistItemsSlice = createSlice({
  name: "blacklistItems",
  initialState,
  reducers: {
    saveBlacklistItems: (state, action: PayloadAction<IBlacklistItem[]>) => {
      state.blacklistItems = action.payload;
    },
    saveBlacklistItem: (state, action: PayloadAction<IBlacklistItem>) => {
      const blacklistItem = state.blacklistItems.find(
        blacklistItem => blacklistItem.assignmentId === action.payload.assignmentId,
      );

      if (blacklistItem === undefined) {
        state.blacklistItems.push(action.payload);
        state.blacklistItems = [...state.blacklistItems];
      }
    },
    deleteBlacklistItem: (state, action: PayloadAction<{ assignmentId: string }>) => {
      state.blacklistItems = state.blacklistItems.filter(
        blacklistItem => blacklistItem.assignmentId !== action.payload.assignmentId,
      );
    },
  },
});

export default blacklistItemsSlice.reducer;
export const { saveBlacklistItems, saveBlacklistItem, deleteBlacklistItem } = blacklistItemsSlice.actions;

export const getBlacklistItem = (assignmentId: string) => (state: RootState) =>
  state.blacklistItems.blacklistItems.find(blacklistItem => blacklistItem.assignmentId === assignmentId);
export const areBlacklistItemsLoaded = (state: RootState) => state.blacklistItems.blacklistItems.length > 0;
