import { Navigate, Route, Routes } from "react-router-dom";

import { SuperTopicPractice } from "./SuperTopicPractice";
import { CombinedTopicPractice } from "./CombinedTopicPractice";
import { TopicPractice } from "./TopicPractice";
import { WorstTopicPractice } from "./WorstTopicPractice";
import { BlacklistedPractice } from "./BlacklistedPractice";
import { BookmarkedPractice } from "./BookmarkedPractice";
import {
  EnvelopePractice,
  EnvelopeCombinedTopicPractice,
  EnvelopeSuperTopicPractice,
  EnvelopeTopicPractice,
} from "./EnvelopePractice";
import { PracticeEvaluation } from "./Evaluation";

const PracticeRoutes = () => {
  return (
    <Routes>
      <Route path="/">
        <Route path="/superokruh/:superTopicId" element={<SuperTopicPractice />} />
        <Route path="/nadokruh/:combinedTopicId" element={<CombinedTopicPractice />} />
        <Route path="/okruh/:topicId" element={<TopicPractice />} />
        <Route path="/okruh-slaby/:topicId" element={<WorstTopicPractice />} />
        <Route path="/ulozene" element={<BookmarkedPractice />} />
        <Route path="/vyrazene" element={<BlacklistedPractice />} />
        <Route path="/obalka/:envelopeId" element={<EnvelopePractice />} />
        <Route path="/obalka/:envelopeId/superokruh/:superTopicId" element={<EnvelopeSuperTopicPractice />} />
        <Route path="/obalka/:envelopeId/nadokruh/:combinedTopicId" element={<EnvelopeCombinedTopicPractice />} />
        <Route path="/obalka/:envelopeId/okruh/:topicId" element={<EnvelopeTopicPractice />} />
        <Route path="/vyhodnoceni" element={<PracticeEvaluation />} />

        <Route path="*" element={<Navigate to="/" />} />
      </Route>
    </Routes>
  );
};

export default PracticeRoutes;
