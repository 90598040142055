import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

import type { RootState } from "store/store";

interface IAssignmentInfo {
  id: string;
  subjectName: string | undefined;
}

interface IAssignmentNavigationState {
  topicAssignments: Record<string, string[]>;
  blacklistedAssignments: Record<string, IAssignmentInfo[]>;
  bookmarkedAssignments: Record<string, IAssignmentInfo[]>;
}

const initialState: IAssignmentNavigationState = {
  topicAssignments: {},
  blacklistedAssignments: {},
  bookmarkedAssignments: {},
};

const assignmentNavigationSlice = createSlice({
  name: "assignmentNavigation",
  initialState,
  reducers: {
    setTopicAssignments: (state, action: PayloadAction<{ topicId: string; assignments: string[] }>) => {
      state.topicAssignments = {
        ...state.topicAssignments,
        [action.payload.topicId]: action.payload.assignments,
      };
    },
    setBlacklistedAssignments: (state, action: PayloadAction<IAssignmentInfo[]>) => {
      const sorted = action.payload.reduce<Record<string, IAssignmentInfo[]>>((acc, assignment) => {
        const subjectName = assignment.subjectName;

        if (subjectName !== undefined) {
          if (acc[subjectName] === undefined) acc[subjectName] = [];
          acc[subjectName].push(assignment);
        }

        return acc;
      }, {});
      state.blacklistedAssignments = sorted;
    },
    setBookmarkedAssignments: (state, action: PayloadAction<IAssignmentInfo[]>) => {
      const sorted = action.payload.reduce<Record<string, IAssignmentInfo[]>>((acc, assignment) => {
        const subjectName = assignment.subjectName;

        if (subjectName !== undefined) {
          if (acc[subjectName] === undefined) acc[subjectName] = [];
          acc[subjectName].push(assignment);
        }

        return acc;
      }, {});
      state.bookmarkedAssignments = sorted;
    },
  },
});

export default assignmentNavigationSlice.reducer;
export const { setBlacklistedAssignments, setBookmarkedAssignments, setTopicAssignments } =
  assignmentNavigationSlice.actions;

export const getTopicAssignments = (topicId: string) => (state: RootState) =>
  state.assignmentsNavigation.topicAssignments[topicId];

export const getBlacklistedAssignments = (subjectName: string | undefined) => (state: RootState) => {
  if (subjectName === undefined || !(subjectName in state.assignmentsNavigation.blacklistedAssignments)) return null;

  return state.assignmentsNavigation.blacklistedAssignments[subjectName];
};

export const getBookmarkedAssignments = (subjectName: string | undefined) => (state: RootState) => {
  if (subjectName === undefined || !(subjectName in state.assignmentsNavigation.bookmarkedAssignments)) return null;

  return state.assignmentsNavigation.bookmarkedAssignments[subjectName];
};
