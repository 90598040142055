import { useEffect, useRef } from "react";

export const useSubjects = <T extends { appName?: string | null }>(subjects: T[] | undefined) => {
  const subjectsRef = useRef<string[]>([]);

  useEffect(() => {
    if (subjects === undefined) return;

    const subjectNames: string[] = [];

    for (const subject of subjects) {
      if (typeof subject.appName === "string") {
        subjectNames.push(subject.appName);
      }
    }

    subjectsRef.current = subjectNames;
  }, [subjects]);

  return subjectsRef;
};
