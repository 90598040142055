import { type CSSProperties, type FC } from "react";

import { sessionResultEnum } from "utils/calculateSessionResult";

import { useTranslation } from "react-i18next";
import { useDateNames } from "../../utils/hooks/useDateNames";
import { useSessionDescription } from "../../utils/hooks/useSessionDescription";

import SnailSVG from "images/icons/snail.svg";
import StrawberrySVG from "images/icons/strawberry.svg";
import WaterDropSVG from "images/icons/waterDrop.svg";
import { useAppSelector } from "store/hooks";
import { getGrade } from "store/slices/user";

const getBackgroundImageStyles = (status: string | null) => {
  const base: CSSProperties = {
    paddingBottom: "10px",
    height: "38px",
    width: "100%",
  };

  if (status === null) return base;

  const styles: CSSProperties = {
    ...base,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    backgroundSize: "contain",
    color: "white",
  };

  if (status === "strawberry") {
    styles.backgroundImage = `url(${StrawberrySVG})`;
  } else if (status === "slime") {
    styles.backgroundImage = `url(${SnailSVG})`;
  } else if (status === "elixir_of_life") {
    styles.backgroundImage = `url(${WaterDropSVG})`;
  }

  return styles;
};

interface Props {
  date: Date;
  isToday: boolean;
  isFinished: boolean;
  withTest: boolean;
  isDeadline: boolean;
  isPlannedExercise: boolean;
  exerciseDuration: number;
  exerciseRealDuration?: number;
  longDate?: boolean;
  longStatusDescription?: boolean;
  status: string | null;
}

const CalendarDayBig: FC<Props> = ({
  date,
  isToday,
  isFinished,
  withTest,
  isDeadline,
  isPlannedExercise,
  exerciseDuration,
  longDate = false,
  longStatusDescription = false,
  status,
}) => {
  const renderSessionDescription = () => {
    if (!isPlannedExercise) return null;

    return <span className="d-none d-md-inline">{getSessionDescriptionByType(sessionResultEnum.PLANNED)}</span>;
  };

  const { getShortDayNameFromDayIndex } = useDateNames();
  const { getSessionDescriptionByType } = useSessionDescription();
  const grade = useAppSelector(getGrade);

  const { t } = useTranslation(["calendar"]);

  const today = new Date();
  today.setHours(0, 0, 0, 0);
  const dateMidnight = new Date(date.setHours(0, 0, 0, 0));
  const isFuture = dateMidnight.getTime() > today.getTime();

  return (
    <div
      className={`${
        isFuture || (isToday && !isFinished) || !isPlannedExercise ? "" : "calendar-day-big"
      } react-calendar__month-view__days__day__inner react-calendar__month-view__days__day__inner--high ${
        isToday ? "today" : ""
      }${status !== null ? " hasIcon" : ""}`}
    >
      {isDeadline && grade === "maturant" ? (
        <div className={"label label-pink"}>{t("dayOfMaturita", { ns: "calendar" })}</div>
      ) : isDeadline ? (
        <div className={"label label-pink"}>{t("dayOfAdmission", { ns: "calendar" })}</div>
      ) : withTest ? (
        <div className={"label label-purple"}>{t("dayOfTest", { ns: "calendar" })}</div>
      ) : null}
      <strong className={`date ${isDeadline ? "text-pink" : withTest ? "text-purple" : ""}`}>
        {longDate ? (
          <>
            {getShortDayNameFromDayIndex(date.getDay())}
            <span className="d-none d-md-inline">{` ${date.getDate()}. ${date.getMonth() + 1}.`}</span>
          </>
        ) : (
          date.getDate()
        )}
      </strong>

      {longStatusDescription ? (
        <div className={`status-text`} style={getBackgroundImageStyles(status)}>
          {(isToday || isFuture) && isPlannedExercise && !isFinished && status === null ? (
            <>
              {renderSessionDescription()}
              <br />
              <span className="d-none d-md-inline">
                {exerciseDuration} {t("minute", { ns: "common", count: exerciseDuration })}
              </span>
              <span className="d-md-none">
                {exerciseDuration}
                {t("minuteShort", { ns: "common" })}
              </span>
            </>
          ) : null}
        </div>
      ) : null}
      {exerciseDuration > 0 && (
        <time className={isToday ? "text-white" : "text-muted"}>
          {exerciseDuration} {t("minute", { ns: "common", count: exerciseDuration })}
        </time>
      )}
    </div>
  );
};

export default CalendarDayBig;
