import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { useGetBookmarksAndSubjectsQuery } from "api/generated";
import { ReactComponent as IconBookmark } from "images/icons/bookmark-colored.svg";
import { AssignmentsOverview } from "features/AssignmentsOverview";
import { useAppDispatch } from "store/hooks";
import { removeResults } from "store/slices/topicPractice";
import { processApiError } from "utils/processApiError";
import { setBookmarksQueryParams } from "store/slices/queryParams";
import { SEARCH_QUERY_PARAM, PAGE_QUERY_PARAM, SUBJECT_QUERY_PARAM } from "const";

export const BookmarksOverview = () => {
  const { t } = useTranslation(["common", "assignment"]);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { data, isLoading, error: apiError, refetch } = useGetBookmarksAndSubjectsQuery();

  const handlePracticeStart = () => {
    dispatch(removeResults());
    navigate("/procvicovani/ulozene");
  };

  const handleQueryParamsChange = ({ searchParam, pageParam, subjectParam }: Record<string, string | null>) => {
    dispatch(
      setBookmarksQueryParams({
        ...(typeof searchParam === "string" ? { [SEARCH_QUERY_PARAM]: searchParam.toString() } : null),
        ...(typeof pageParam === "string" ? { [PAGE_QUERY_PARAM]: pageParam.toString() } : null),
        ...(typeof subjectParam === "string" ? { [SUBJECT_QUERY_PARAM]: subjectParam } : null),
      }),
    );
  };

  const getTopicHref = (userAssignmentId: string) => {
    return `/ulozene/${userAssignmentId}`;
  };

  useEffect(() => {
    if (isLoading) return;
    refetch().catch(console.error);
  }, []);

  useEffect(() => {
    if (apiError === undefined) return;

    processApiError(apiError);
  }, [apiError]);

  return (
    <AssignmentsOverview
      assignments={data?.bookmarks}
      subjects={data?.subjects}
      getTopicHref={getTopicHref}
      isLoading={isLoading}
      onPracticeStart={handlePracticeStart}
      onQueryParamsChange={handleQueryParamsChange}
      pageIcon={<IconBookmark />}
      pageTitle={t("pageTitle.savedExercises", { ns: "envelope" })}
    />
  );
};
