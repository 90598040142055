import { useEffect, useState } from "react";

import type { GetTimetableQuery, StudyDayFragment } from "api/generated";

import { useAppSelector } from "store/hooks";
import { getUserEmail, selectAdmissionsDeadline, selectDateOfRegistration } from "store/slices/user";

import { formatDate } from "utils/calendarDateFormatting";
import { getSessionFromStorage } from "utils/sessionStorageHandler";

const calculateInterruptedExerciseTime = (email: string | null | undefined) => {
  const sessionData = getSessionFromStorage(email);

  if (sessionData === null) return 0;

  return sessionData.spentTime;
};

export const useWeekData = <T extends GetTimetableQuery>(data: T | undefined) => {
  const admissionsDeadline = useAppSelector(selectAdmissionsDeadline);
  const dateOfRegistration = useAppSelector(selectDateOfRegistration);
  const userEmail = useAppSelector(getUserEmail);

  const interruptedExerciseTime = calculateInterruptedExerciseTime(userEmail);

  const [weekData, setWeekData] = useState<{
    studyDays: StudyDayFragment[];
    tests: string[];
    admissionsDeadline: string;
    dateOfRegistration: string | null;
  }>({
    studyDays: [],
    tests: [],
    admissionsDeadline: "",
    dateOfRegistration: null,
  });

  const today = formatDate(new Date());
  const todayResult = weekData.studyDays.find(day => day.date === today);

  const isTrainingFinished = todayResult?.sessionFinished === true;

  const isTrainingPartiallyDone =
    todayResult !== undefined &&
    typeof todayResult.duration === "number" &&
    todayResult.duration > 0 &&
    todayResult.sessionFinished !== true &&
    interruptedExerciseTime > 0;

  useEffect(() => {
    if (data === undefined || data.timetable === undefined || admissionsDeadline === "undefined") return;

    const studyDays: StudyDayFragment[] = [];
    for (const timetable of data.timetable.timetable) {
      for (const plan of timetable.plan) {
        studyDays.push(plan);
      }
    }

    const tests = data.testPresence.map(presence => presence.dateTime.split(" ")[0]);
    setWeekData({ studyDays, tests, admissionsDeadline, dateOfRegistration });
  }, [data, admissionsDeadline, dateOfRegistration]);

  return { isTrainingFinished, isTrainingPartiallyDone, weekData };
};
