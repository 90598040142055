import { useEffect, useState } from "react";

import { SEARCH_QUERY_PARAM } from "const";
import type { IAssignmentsOverviewTabAssignment } from "./AssignmentsOverviewTab";
import { searchInAssignment } from "utils/searchInAssignment";

export const useSearch = (
  assignments: IAssignmentsOverviewTabAssignment[] | null,
  searchParams: URLSearchParams,
  removeAllQueryParams: () => void,
) => {
  const search = searchParams.get(SEARCH_QUERY_PARAM);

  const [foundAssignments, setFoundAssignments] = useState<IAssignmentsOverviewTabAssignment[] | null>(null);

  const searchAssignments = (searchedString: string) => {
    if (assignments === null) return;

    const found: IAssignmentsOverviewTabAssignment[] = [];

    for (const assignment of assignments) {
      if (searchInAssignment(assignment, searchedString)) {
        found.push(assignment);
      }
    }

    setFoundAssignments(found);
  };

  useEffect(() => {
    if (assignments === null) return;

    if (search === null || search.length < 1) {
      setFoundAssignments(null);
      return;
    }

    searchAssignments(search);
  }, [assignments]);

  useEffect(() => {
    if (assignments === null) return;

    if (search === null || search.length < 1) {
      if (searchParams.get(SEARCH_QUERY_PARAM) !== null) {
        removeAllQueryParams();
      }
      setFoundAssignments(null);

      return;
    }

    const timer = setTimeout(() => {
      searchAssignments(search);
    }, 250);

    return () => {
      clearTimeout(timer);
    };
  }, [search]);

  return { foundAssignments, search };
};
