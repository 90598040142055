import { useEffect } from "react";

import PracticePageContent from "../_PracticePageContent";

import { useGetBlacklistedPracticeAssignmentsQuery } from "api/generated";

import { processApiError } from "utils/processApiError";

const TOPIC_ID = "blacklist";

export const BlacklistedPractice = () => {
  const { data, isLoading, error: apiError } = useGetBlacklistedPracticeAssignmentsQuery();

  useEffect(() => {
    if (apiError === undefined) return;

    processApiError(apiError);
  }, [apiError]);

  return (
    <PracticePageContent
      isLoading={isLoading}
      assignments={data?.blacklistItems.map(blacklistItem => blacklistItem.userAssignment) ?? null}
      title="Vyřazené úlohy"
      topicId={TOPIC_ID}
    />
  );
};
