import { useEffect, useRef, useState } from "react";
import type { FormEvent } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Form } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Select from "react-select";

import { PhoneInput } from "components";

import { useUpdateUserPreferencesMutation, useSetOnboardingIntroducedMutation } from "api/generated";

import { useAppDispatch, useAppSelector } from "store/hooks";
import {
  setPreferences,
  selectOnboardingStatus,
  setOnboardingIntroduced,
  setPhoneValidationDateTimeLast,
} from "store/slices/user";

import { isValidPhoneNumber } from "utils/phoneValidator";
import { processApiError } from "utils/processApiError";
import { useSubjects } from "utils/hooks/useSubjects";

interface IFormData {
  preferredSubject: string;
  phoneNumber: string;
}

const StudyPreferences = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const onboardingStatus = useAppSelector(selectOnboardingStatus);
  const { data: subjects, isLoading: isLoadingSubjects } = useSubjects();
  const [updateUserPreferencesMutation, { error: apiUpdateUserError }] = useUpdateUserPreferencesMutation();
  const [setOnboardingIntroducedMutation, { error: apiUpdateOnboardingError }] = useSetOnboardingIntroducedMutation();

  const { t } = useTranslation(["common", "onboarding"]);

  const isSavingStatus = useRef(false);

  const [formData, setFormData] = useState<IFormData>({
    preferredSubject: "",
    phoneNumber: "",
  });

  useEffect(() => {
    if (!onboardingStatus.introduced && !isSavingStatus.current) {
      isSavingStatus.current = true;
      setOnboardingIntroducedMutation()
        .then(() => {
          dispatch(setOnboardingIntroduced());
        })
        .finally(() => {
          isSavingStatus.current = false;
        });
    }
  }, []);

  useEffect(() => {
    if (apiUpdateUserError === undefined) return;

    processApiError(apiUpdateUserError);
  }, [apiUpdateUserError]);

  useEffect(() => {
    if (apiUpdateOnboardingError === undefined) return;

    processApiError(apiUpdateOnboardingError);
  }, [apiUpdateOnboardingError]);

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (isFormFilled) {
      updateUserPreferencesMutation({ ...formData, smsEnabled: true })
        .then(response => {
          dispatch(setPreferences({ ...formData, smsEnabled: true }));
          if ("data" in response) {
            const datetimeLast = response.data.updatePhone.datetimeLast;
            if (typeof datetimeLast === "string") {
              dispatch(setPhoneValidationDateTimeLast(datetimeLast));
            }
          }
          navigate("/onboarding/overeni-telefonu");
        })
        .catch(error => {
          console.error(error);
        });
    }
  };

  const handleChange = (type: keyof IFormData, value: string | undefined) => {
    setFormData(data => {
      return { ...data, [type]: value };
    });
  };

  const handlePhoneChange = (phoneNumber: string) => {
    setFormData(data => {
      return { ...data, phoneNumber };
    });
  };

  const isFormFilled = formData.preferredSubject !== "" && isValidPhoneNumber(formData.phoneNumber);

  const subjectSelectOptions = subjects?.map(subject => {
    return {
      value: subject,
      label: subject,
    };
  });

  return (
    <>
      <main className={"h-100 d-flex flex-column justify-content-between justify-content-sm-start"}>
        <div className="page px-3">
          <div className="page__header">
            <div className="steps-indicator mb-40px">
              <div className={"dot active"} />
              <div className={"dot"} />
              <div className={"dot"} />
            </div>
          </div>

          <div className="page__content">
            <Form onSubmit={handleSubmit} id={"studePreferenceForm"}>
              <h1 className={"h2 mb-3"}>{t("studyPreferences.heading", { ns: "onboarding" })}</h1>
              <Form.Group controlId={"subject"}>
                <Form.Label>{t("studyPreferences.subjectHeading", { ns: "onboarding" })}</Form.Label>

                <Select
                  name="subject"
                  id="subject"
                  className={"react-select-container"}
                  classNamePrefix="react-select"
                  placeholder={t("studyPreferences.subjectSelectPlaceholder", { ns: "onboarding" })}
                  isLoading={isLoadingSubjects}
                  options={subjectSelectOptions}
                  onChange={e => {
                    handleChange("preferredSubject", e?.value);
                  }}
                  isSearchable={false}
                />
              </Form.Group>

              <div className={"mt-3 mb-40px"}>
                <PhoneInput
                  onPhoneChange={handlePhoneChange}
                  label={t("studyPreferences.phoneInputLabel", { ns: "onboarding" })}
                  phoneNumber={formData.phoneNumber}
                />
              </div>
            </Form>
          </div>

          <div className="page__footer">
            <Button
              type={"submit"}
              disabled={!isFormFilled}
              className={"w-100 text-uppercase"}
              form={"studePreferenceForm"}
            >
              {t("continue")}
            </Button>
          </div>
        </div>
      </main>
    </>
  );
};

export default StudyPreferences;
