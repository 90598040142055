import { useState } from "react";
import Button, { type ButtonProps } from "react-bootstrap/Button";

import { ReactComponent as IconBlacklist } from "images/icons/20-blacklist.svg";
import { ReactComponent as IconBlacklistBack } from "images/icons/20-blacklist-back.svg";

import type { NoStringIndexType } from "utils/types/NoStringIndexType";

import { BlacklistInConfirmModal } from "./BlacklistInConfirmModal";
import { BlacklistOutConfirmModal } from "./BlacklistOutConfirmModal";
import { useBlacklistItems } from "./useBlacklistItems";

interface Props extends Omit<NoStringIndexType<ButtonProps>, "onClick"> {
  userAssignmentId: string;
}

export const BlacklistButton: React.FC<Props> = ({ className, userAssignmentId, ...restProps }) => {
  const [modalVisible, setModalVisible] = useState(false);
  const { toggleBlacklistItem, isLoadingData, isBlacklisted } = useBlacklistItems(userAssignmentId);

  const handleButtonClick = () => {
    setModalVisible(true);
  };

  const handleConfirm = () => {
    if (isLoadingData) return;

    toggleBlacklistItem();
    setModalVisible(false);
  };

  const handleModalClose = () => {
    setModalVisible(false);
  };

  return (
    <>
      <Button
        size="sm"
        variant="navigation"
        onClick={handleButtonClick}
        id="black-list"
        className={`p-0 no-stroke-color ${className ?? ""}`}
        active={true}
        {...restProps}
      >
        {isBlacklisted ? <IconBlacklistBack /> : <IconBlacklist />}
      </Button>
      {modalVisible ? (
        isBlacklisted ? (
          <BlacklistOutConfirmModal isLoading={isLoadingData} onClose={handleModalClose} onConfirm={handleConfirm} />
        ) : (
          <BlacklistInConfirmModal isLoading={isLoadingData} onClose={handleModalClose} onConfirm={handleConfirm} />
        )
      ) : null}
    </>
  );
};
