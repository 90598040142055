import { Col, Container, Row } from "react-bootstrap";

import {
  BlackEnvelopeBox,
  Loading,
  OutageBox,
  PracticeInMhdBox,
  WelcomeMessage,
  WelcomeNewUserModal,
} from "components";
import { CheckWelcomeBadges, DashboardCalendarOverview, DashboardEnvelopesSummary, NewestBadges } from "features";

import { useAppSelector } from "store/hooks";
import { selectUserWelcomed, getGrade } from "store/slices/user";

import { isMobile } from "utils/isMobile";
import { useDashboardData } from "utils/hooks/useDashboardData";

const Dashboard = () => {
  const userWelcomed = useAppSelector(selectUserWelcomed);
  const grade = useAppSelector(getGrade);
  const { data, isLoading } = useDashboardData();

  return (
    <>
      <Container className={"container-mw-md page-container pt-25px pt-sm-45px"}>
        {isLoading ? (
          <Loading />
        ) : (
          <>
            <Row>
              <Col>
                <OutageBox data={data?.displayOutages} />
                <WelcomeMessage />
                <DashboardCalendarOverview data={data} />
                <Row className={"g-24px"}>
                  {isMobile() && (
                    <Col md={6}>
                      <PracticeInMhdBox />
                    </Col>
                  )}

                  <Col md={isMobile() ? 6 : 12}>
                    <BlackEnvelopeBox data={data?.blindSpotTopics} />
                  </Col>
                </Row>
                <DashboardEnvelopesSummary data={data?.envelopesSummary} />
                <NewestBadges data={data?.lastAchievements} />
              </Col>
            </Row>

            <CheckWelcomeBadges data={data?.checkAchievements} />
            {!userWelcomed && grade !== "maturant" ? <WelcomeNewUserModal /> : null}
          </>
        )}
      </Container>
    </>
  );
};

export default Dashboard;
